// ==========================================================================
// Typography
// ==========================================================================

// Roboto
@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
         url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
         url('../fonts/Roboto/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Open Sans
@font-face {
    font-family: "Open Sans";
    src: url('../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
         url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url('../fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
         url('../fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url('../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
         url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@layer base {

    /* Headings */
    .h1 { @apply font-heading tracking-tight text-6xl font-black leading-none  }
    .h2 { @apply font-heading tracking-tight text-5xl font-bold leading-tight }
    .h3 { @apply font-heading tracking-tight text-4xl font-bold leading-tight }
    .h4 { @apply font-heading tracking-tight text-3xl font-bold leading-tight }
    .h5 { @apply font-heading text-2xl font-semibold leading-tight }
    .h6 { @apply font-heading text-lg font-semibold leading-tight }

    /* Body */
    .body-2xl { @apply text-2xl font-normal leading-normal }
    .body-xl { @apply text-xl font-normal leading-normal }
    .body-lg { @apply text-lg font-normal leading-relaxed }
    .body-md { @apply text-base font-normal leading-relaxed }
    .body-sm { @apply text-sm font-normal leading-relaxed }
    .body-xs { @apply text-xs font-normal leading-relaxed }

}

@layer utilities {

    .copy {

        @apply space-y-1.5 lg:space-y-3;

        italic { @apply italic; }

        bold { @apply font-bold; }

        a { @apply font-bold text-secondary-400 transition hover:text-secondary-500 active:text-secondary-300; }

        &> ul {

            @apply list-disc list-outside ml-[1.2em];

            li::marker { @apply text-secondary-400; }
        }
    }
}
